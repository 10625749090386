@media screen and (min-width: 400px) {
  #menu a.navbar-brand {
    font-size: 24px;
  }
}

@media screen and (max-width: 500px) {
  .service-image {
    display: flex;
    justify-content: center;
  }
}

@media screen and (min-width: 500px) {
  .service-image {
    float: left;
  }
}

@media screen and (min-width: 600px) {
  .intro h1 {
    font-size: 82px;
  }
}